import client from '@/data/network/rest/client';
import { API_ENDPOINTS } from '@/data/network/rest/client/api-endpoint';
import { AUTH_REFRESH_KEY } from '@/lib/constants';
import {
  accessTokenAtom,
  authorizationAtom,
  useUpdateAccessToken
} from '@/store/authorization-atom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { atomsWithQuery } from 'jotai-tanstack-query';
import { RESET } from 'jotai/utils';
import Cookies from 'js-cookie';
import { useRouter } from 'next/navigation';
import { toast } from 'react-toastify';

export function useAuthentication() {
  useUpdateAccessToken();
  const [isAuthorized] = useAtom(authorizationAtom);
  return { isAuthorized };
}

export function useUser() {
  useUpdateAccessToken();
  const [, setAccessToken] = useAtom(accessTokenAtom);
  const [isAuthorized] = useAtom(authorizationAtom);
  const { data, isLoading, error } = useQuery(
    [API_ENDPOINTS.USERS_ME],
    client.users.me,
    {
      enabled: isAuthorized,
      retry: 0, // Do not retry the query if it fails
      onError: _err => {
        setAccessToken(RESET);
      }
    }
  );
  return {
    me: isAuthorized ? data : undefined,
    isLoading,
    error,
    isAuthorized
  };
}

export const useLogoutUser = () => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const [, setAccessToken] = useAtom(accessTokenAtom);

  return useMutation(client.users.logout, {
    onSuccess: async () => {
      setAccessToken(RESET);
      Cookies.remove(AUTH_REFRESH_KEY);
      router.replace('/');
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([API_ENDPOINTS.USERS_ME]);
      await queryClient.invalidateQueries([API_ENDPOINTS.USERS_PROFILE]);
    }
  });
};

export const useDeleteUser = (onCloseModal: () => void) => {
  const queryClient = useQueryClient();
  const router = useRouter();
  const [, setAccessToken] = useAtom(accessTokenAtom);
  return useMutation(client.users.delete, {
    onSuccess: async _data => {
      router.replace('/');
      setAccessToken(RESET);
      onCloseModal();
    },
    onError: error => {
      const {
        response: { status }
      }: any = error ?? {};
      if (status === 403) {
        toast.error('Password is wrong');
      } else if (status === 400) {
        toast.error('Please enter your password');
      }
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([API_ENDPOINTS.USERS_ME]);
      await queryClient.invalidateQueries([API_ENDPOINTS.USERS_PROFILE]);
    }
  });
};

export function useUserProfile() {
  const { data, isLoading, error } = useQuery(
    [API_ENDPOINTS.USERS_PROFILE],
    client.users.detail,
    {
      onError: _err => {}
    }
  );
  // TODO: do some improvement here
  return { me: data, isLoading, error };
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient();
  return useMutation(client.users.update, {
    onSuccess: _data => {
      toast.success('Update Successful!');
    },
    onError: _error => {
      toast.error('Update Fail');
    },
    onSettled: async () => {
      await queryClient.refetchQueries([API_ENDPOINTS.USERS_PROFILE]);
    }
  });
};

export const useCreatePassword = (onCloseModal: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(client.users.createPassword, {
    onSuccess: async _data => {
      toast.success('Update Successful!');
      onCloseModal();
    },
    onError: _error => {
      toast.error(_error.response.data.errors[0].detail);
    },
    onSettled: async () => {
      await queryClient.refetchQueries([API_ENDPOINTS.USERS_PROFILE]);
    }
  });
};

export const useChangePassword = (onCloseModal: () => void) => {
  const queryClient = useQueryClient();
  return useMutation(client.users.changePassword, {
    onSuccess: async _data => {
      toast.success('Update Successful!');
      onCloseModal();
    },
    onError: _error => {
      toast.error(_error.response.data.errors[0].detail);
    },
    onSettled: async () => {
      await queryClient.refetchQueries([API_ENDPOINTS.USERS_PROFILE]);
    }
  });
};

export const useConnectSocialAccount = () => {
  const queryClient = useQueryClient();
  return useMutation(client.users.connectSocialAccount, {
    onSuccess: _data => {},
    onError: () => {
      toast.error('Social Connect Failed');
    },
    onSettled: async () => {
      await queryClient.refetchQueries([API_ENDPOINTS.USERS_PROFILE]);
    }
  });
};

export const useDisconnectSocialAccount = () => {
  const queryClient = useQueryClient();
  return useMutation(client.users.disconnectSocialAccount, {
    onSuccess: _data => {
      toast.success('Disconnect is success');
    },
    onError: () => {
      toast.error('Disconnect is failed');
    },
    onSettled: async () => {
      await queryClient.refetchQueries([API_ENDPOINTS.USERS_PROFILE]);
    }
  });
};

export const usePaymentComplete = () => {
  const queryClient = useQueryClient();
  const router = useRouter();
  return useMutation(client.users.paymentFeature, {
    onSuccess: _data => {
      toast.success('Update Successful!');
      router.refresh();
    },
    onError: () => {
      toast.error('Update Fail');
    },
    onSettled: async () => {
      await queryClient.invalidateQueries([API_ENDPOINTS.USERS_ME]);
    }
  });
};

export const [overayPlatformDownloadsAtom] = atomsWithQuery(get => ({
  refetchOnWindowFocus: false,
  queryKey: [API_ENDPOINTS.LATEST_OVERAY_PLATFORMS],
  queryFn: async () => {
    const res = await client.users.overayPlatformDownloads();
    return res;
  }
}));

export function useCardInfo() {
  const { data, isLoading, error } = useQuery(
    [API_ENDPOINTS.USERS_CARD_INFO],
    client.users.cardInfo
  );
  return { data, isLoading, error };
}

export function useChangeRegisteredCard() {
  return useMutation(client.users.changeRegisteredCard, {
    onError: () => {
      toast.error('Failed to get your payment method');
    }
  });
}
