import { API_ENDPOINTS } from '@/data/network/rest/client/api-endpoint';
import {
  HttpAuthClient,
  HttpClient
} from '@/data/network/rest/client/http-client';
import { AUTH_REFRESH_KEY } from '@/lib/constants';
import type {
  ADVANCED_FEATURE_TYPE,
  AdvancedFeatureProduct,
  ApplicationDownloadInfo,
  CardInfo,
  ChangeCardReqBody,
  ChangeCardResponse,
  CheckRegisteredCardReqBody,
  CountryCode,
  PlanContent,
  PlanType,
  PreOrdersReqBody,
  PreOrdersResponse,
  PresignedPostData,
  PresignedPostResponse,
  RecentPaymentsResponse,
  UpdateUserInput,
  User,
  UserDetail,
  UserPasswordEditInput
} from '@/ts/interfaces/overay_studio';
import Cookies from 'js-cookie';
import * as process from 'process';

class Client {
  users = {
    me: async () => await HttpClient.get<User>(API_ENDPOINTS.USERS_ME),
    createPassword: async (data: UserPasswordEditInput) =>
      await HttpClient.post(API_ENDPOINTS.USERS_CREATE_PASSWORD, data),
    changePassword: async (data: UserPasswordEditInput) =>
      await HttpClient.post(API_ENDPOINTS.USERS_CHANGE_PASSWORD, data),
    detail: async () =>
      await HttpClient.get<UserDetail>(API_ENDPOINTS.USERS_PROFILE),
    update: async (user: UpdateUserInput) =>
      await HttpClient.patch(API_ENDPOINTS.USERS_PROFILE, user),
    delete: async (password: string) =>
      await HttpClient.post(API_ENDPOINTS.USERS_DELETE, { password }),
    paymentFeature: async (feature: ADVANCED_FEATURE_TYPE) =>
      await HttpClient.post(API_ENDPOINTS.USERS_PAYMENTS_COMPLETE, { feature }),
    connectSocialAccount: async ({
      provider,
      providerId,
      extraData
    }: {
      provider: string;
      providerId: string;
      extraData: string;
    }) =>
      await HttpClient.post(`${API_ENDPOINTS.ACCOUNT}/${provider}/connect`, {
        provider,
        providerId,
        extraData: JSON.stringify(extraData)
      }),
    disconnectSocialAccount: async ({
      provider,
      providerId
    }: {
      provider: string;
      providerId: string;
    }) => {
      return await HttpClient.post(
        `${API_ENDPOINTS.ACCOUNT}/${provider}/disconnect`,
        {
          provider,
          providerId
        }
      );
    },
    logout: async () => {
      await HttpAuthClient.post<boolean>(API_ENDPOINTS.REVOKE_TOKEN, {
        client_id: process.env.OVERAY_STUDIO_CLIENT_ID,
        client_secret: process.env.OVERAY_STUDIO_CLIENT_SECRET,
        token: Cookies.get(AUTH_REFRESH_KEY)
      });
    },
    overayPlatformDownloads: async () =>
      await HttpClient.get<ApplicationDownloadInfo[]>(
        API_ENDPOINTS.LATEST_OVERAY_PLATFORMS
      ),
    cardInfo: async () =>
      await HttpClient.get<CardInfo>(API_ENDPOINTS.USERS_CARD_INFO),
    changeRegisteredCard: async (postData: ChangeCardReqBody) =>
      await HttpClient.post<ChangeCardResponse>(
        API_ENDPOINTS.USERS_CHANGE_CARD,
        postData
      )
  };

  products = {
    advancedFeature: async () =>
      await HttpClient.get<AdvancedFeatureProduct[]>(
        API_ENDPOINTS.ADVANCED_FEATURE_PRODUCTS
      )
  };

  files = {
    presignedPost: async (postData: PresignedPostData) =>
      await HttpClient.post<PresignedPostResponse>(
        API_ENDPOINTS.PRESIGNED_POST,
        postData
      ),
    uploadFile: async (url: string, data: unknown) =>
      await HttpClient.post(url, data, {}),
    deleteFile: async (filePath: string) =>
      await HttpClient.post(API_ENDPOINTS.FILE_DELETE, { file_path: filePath })
  };

  payments = {
    plan: async (type: PlanType, countryCode: CountryCode) =>
      await HttpClient.get<PlanContent>(
        `${API_ENDPOINTS.PLANS}?type=${type}&country=${countryCode}`
      ),
    preOrders: async (postData: PreOrdersReqBody) =>
      await HttpClient.post<PreOrdersResponse>(
        API_ENDPOINTS.SUBSCRIPTIONS_PRE_ORDERS,
        postData
      ),
    checkRegisteredCard: async (postData: CheckRegisteredCardReqBody) =>
      await HttpClient.post(API_ENDPOINTS.SUBSCRIPTIONS_PAYMENTS, postData),
    recentPayments: async (size: number, page: number) =>
      await HttpClient.get<RecentPaymentsResponse>(
        `${API_ENDPOINTS.SUBSCRIPTIONS_TRANSACTIONS}?size=${size}&page=${page}`
      ),
    cancelSubscriptions: async (reason: string) =>
      await HttpClient.post(API_ENDPOINTS.SUBSCRIPTIONS_CANCEL, { reason })
  };
}

export default new Client();
