'use client';

import { PrivateRoute } from '@/lib/PrivateRoute';
import { useEffect } from 'react';
import { Footer } from './Footer';
import { Header } from './Header';
interface SiteLayoutProps {
  themeMode: 'black' | 'white';
  authenticationRequired: boolean;
  children: React.ReactNode;
}
export default function SiteLayout({
  themeMode,
  authenticationRequired,
  children
}: SiteLayoutProps) {
  useEffect(() => {
    document.body.classList.remove(themeMode !== 'white' ? 'bg-white' : 'bg-black-background');
    document.body.classList.remove(themeMode !== 'white' ? 'text-gray-900' : 'text-white');
    document.body.classList.add(themeMode === 'white' ? 'bg-white' : 'bg-black-background');
    document.body.classList.add(themeMode === 'white' ? 'text-gray-900' : 'text-white');
  });
  return <>
      {authenticationRequired ? <PrivateRoute>
          <Header themeMode={themeMode} hideHeaderButtons={authenticationRequired} />
          <main>{children}</main>
          <Footer themeMode={themeMode} />
        </PrivateRoute> : <>
          <Header themeMode={themeMode} hideHeaderButtons={authenticationRequired} />
          <main>{children}</main>
          <Footer themeMode={themeMode} />
        </>}
    </>;
}