export const RESOURCE_BASE_URL = process.env.RESOURCE_URL as string;
export const RESOURCE_IMAGE_BASE_URL: string = RESOURCE_BASE_URL + '/images';
export const IMAGE_ENDPOINTS = {
  TWO_MONITOR: RESOURCE_IMAGE_BASE_URL + '/two-monitor-icon.png',
  ONE_MONITOR: RESOURCE_IMAGE_BASE_URL + '/one-monitor-icon.png',
  LOGO_BLACK_BETA: RESOURCE_IMAGE_BASE_URL + '/logo-black-beta.png',
  LOGO_WHITE_BETA: RESOURCE_IMAGE_BASE_URL + '/logo-white-beta.png',
  DESKTOP_HOST_1: RESOURCE_IMAGE_BASE_URL + '/desktop-host3.png',
  OVERAY_LOGO_WHITE: RESOURCE_IMAGE_BASE_URL + '/overay-logo-white.png',
  OVERAY_LOGO_BLACK: RESOURCE_IMAGE_BASE_URL + '/overay-logo-black.png'
};
export const CONNECTION_GUIDE_IMAGE_ENDPOINTS = {
  STEP4: RESOURCE_IMAGE_BASE_URL + '/connection-guide/connection-guide4.png',
  STEP5: RESOURCE_IMAGE_BASE_URL + '/connection-guide/connection-guide5.png',
  STEP6: RESOURCE_IMAGE_BASE_URL + '/connection-guide/connection-guide6.png',
  STEP7: RESOURCE_IMAGE_BASE_URL + '/connection-guide/connection-guide7.png',
  STEP8: RESOURCE_IMAGE_BASE_URL + '/connection-guide/connection-guide8.png'
};

export const ICO_ENDPOINTS = {
  FAVICON: RESOURCE_BASE_URL + '/favicon.ico'
};

export const PREVIEW_ENDPOINTS = {
  PREVIEW: RESOURCE_BASE_URL + '/preview.png'
};

export const RESOURCE_VIDEO_BASE_URL: string = RESOURCE_BASE_URL + '/videos';
export const VIDEO_ENDPOINTS = {
  VIRTUAL_MONITOR: RESOURCE_VIDEO_BASE_URL + '/virtual-monitors.mp4',
  VIRTUAL_SPACES: RESOURCE_VIDEO_BASE_URL + '/virtual-spaces.mp4',
  OVERAY_HERO: RESOURCE_VIDEO_BASE_URL + '/overay-hero.mp4',
  OVERAYSTUDIO_DEMO: RESOURCE_VIDEO_BASE_URL + '/overaystudio-demo.mp4'
};
