'use client';

import { Logo } from '@/components/Icons/Logo';
import { useLogoutUser, useUser } from '@/data/network/rest/user';
import { useI18nPathname } from 'i18n/request';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
export interface Props {
  themeMode: 'black' | 'white';
  hideHeaderButtons?: boolean;
}
export const Header: React.FC<Props> = ({
  themeMode,
  hideHeaderButtons
}) => {
  const t = useTranslations('header');
  const {
    isAuthorized,
    me
  } = useUser();
  const pathname = useI18nPathname();
  const isUserSetting = pathname?.startsWith('/accounts');
  const {
    mutate: logoutUser
  } = useLogoutUser();
  const isDevelopment = process.env.NODE_ENV === 'development';
  const fullUrl = isDevelopment ? `?next=/accounts/login-success?next=${encodeURIComponent(process.env.NEXTAUTH_URL ?? '')}` : '';
  const signInLink = `${process.env.ACCOUNT_OVERAY_STUDIO ?? ''}/accounts/login${fullUrl}`;

  // fix hydration error & <Button> for `log-in`, `log-out`, `profile`
  const [linkButton, setLinkButton] = useState({
    href: signInLink,
    name: t('log_in'),
    onPressed: () => {}
  });
  useEffect(() => {
    const href = isAuthorized ? isUserSetting ? '' : '/accounts' : signInLink;
    const name = isAuthorized ? isUserSetting ? t('log_out') : t('profile') : t('log_in');
    const onPressed = isAuthorized && isUserSetting ? logoutUser : () => {};
    setLinkButton({
      href,
      name,
      onPressed
    });
  }, [isAuthorized, isUserSetting, logoutUser, signInLink, t]);
  const isBlackBackground = themeMode === 'black';
  const isWhiteBackground = themeMode === 'white';
  const navigation = me?.email === 'test@test.com' || me?.email === 'dition0221dev@gmail.com' ? [{
    name: t('features'),
    href: '/#VirtualMonitorSection'
  }, {
    name: t('download'),
    href: '/#DownloadSection'
  }, {
    name: t('company'),
    href: '/about'
  }, {
    name: t('guide'),
    href: process.env.GITBOOK_URL,
    external: true
  }, {
    name: t('community'),
    href: process.env.DISCORD_INVITE_URL,
    external: true
  }, {
    name: t('plan'),
    href: '/plan'
  }] : [{
    name: t('features'),
    href: '/#VirtualMonitorSection'
  }, {
    name: t('download'),
    href: '/#DownloadSection'
  }, {
    name: t('company'),
    href: '/about'
  }, {
    name: t('guide'),
    href: process.env.GITBOOK_URL,
    external: true
  }, {
    name: t('community'),
    href: process.env.DISCORD_INVITE_URL,
    external: true
  }
  // TODO: temporary deleted `/plan` page
  // { name: t('plan'), href: '/plan' }
  ];
  return <header className={classNames('px-6 py-6', {
    'bg-black-background': isBlackBackground,
    'bg-white': isWhiteBackground
  })} data-sentry-component="Header" data-sentry-source-file="Header.tsx">
      <Disclosure as="nav" data-sentry-element="Disclosure" data-sentry-source-file="Header.tsx">
        {({
        open
      }) => <>
            <div className="h-10 flex justify-between items-center">
              <div className="w-1/3 lg:mr-10 xl:mr-0 flex justify-start" aria-label="Global">
                <Logo themeMode={themeMode} />
              </div>
              <div className="hidden lg:w-fit lg:flex lg:justify-center lg:gap-x-12">
                {navigation.map(item => item.external ? <a key={item.name} href={item.href} target="_blank" rel="noopener noreferrer" className={classNames('font-bold whitespace-nowrap', {
              'text-white hover:text-gray-200': isBlackBackground || isWhiteBackground
            })}>
                      {item.name}
                    </a> : <Link key={item.name} href={item.href} hidden={hideHeaderButtons} className={classNames('font-bold whitespace-nowrap', {
              'text-white hover:text-gray-200': isBlackBackground || isWhiteBackground
            })}>
                      {item.name}
                    </Link>)}
              </div>

              {/* PC: log-in/profile button */}
              <div className="hidden lg:w-1/3 lg:flex lg:justify-end">
                {linkButton.href !== '' ? <Link href={linkButton.href} className={classNames('inline-block rounded-lg px-6 py-1.5 leading-6 text-base font-bold text-white shadow-sm', {
              'text-white bg-overay-800 hover:bg-overay-900': isBlackBackground,
              'text-white bg-gray-400 hover:bg-gray-600': isWhiteBackground
            })}>
                    {linkButton.name}
                  </Link> : <button onClick={() => {
              linkButton.onPressed();
            }} className={classNames('inline-block rounded-lg px-6 py-1.5 leading-6 text-base font-bold text-white shadow-sm', {
              'text-white bg-overay-800 hover:bg-overay-900': isBlackBackground,
              'text-white bg-gray-400 hover:bg-gray-600': isWhiteBackground
            })}>
                    {linkButton.name}
                  </button>}
              </div>

              {/* mobile menu button */}
              <div className="flex lg:hidden">
                <Disclosure.Button className={classNames('-m-2.5 inline-flex items-center justify-center rounded-md p-2.5', {
              'hover:bg-gray-100': isWhiteBackground,
              'hover:bg-overay-700': isBlackBackground
            })}>
                  <span className="sr-only">Open main menu</span>
                  {open ? <XMarkIcon className={'block h-6 w-6'} aria-hidden="true" color={isBlackBackground ? 'white' : 'black'} /> : <Bars3Icon className="block h-6 w-6 " aria-hidden="true" color={isBlackBackground ? 'white' : 'black'} />}
                </Disclosure.Button>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden mt-6 flow-root">
              <div className="divide-y divide-gray-500/10">
                <div className={classNames('space-y-2 py-6', {
              hidden: hideHeaderButtons === true
            })}>
                  {navigation.map(item => <Disclosure.Button key={item.name} as={Link} href={item.href} className={classNames('block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg hover:bg-gray-400/10', {
                'text-white': isBlackBackground,
                'text-gray-700': isWhiteBackground
              })} aria-current={undefined}>
                      {item.name}
                    </Disclosure.Button>)}
                </div>

                <div className="py-6">
                  <Disclosure.Button key={linkButton.name} as={linkButton.href === '' ? undefined : Link} href={linkButton.href} onClick={() => {
                linkButton.onPressed();
              }} className={classNames('block px-3 py-2 -mx-3 text-base font-semibold leading-7 rounded-lg hover:bg-gray-400/10', {
                'text-white': isBlackBackground,
                'text-gray-700': isWhiteBackground
              })} aria-current={undefined}>
                    {linkButton.name}
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>}
      </Disclosure>
    </header>;
};