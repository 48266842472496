import Cookies from 'js-cookie';
export function getCookieDomain(domain?: string): string {
  if (domain?.includes('localhost')) {
    return 'localhost';
  } else if (domain?.includes('overaystudio.com')) {
    return '.overaystudio.com';
  } else if (domain?.includes('underay.xyz')) {
    return '.underay.xyz';
  } else {
    return '';
  }
}
const TARGET_DOMAIN: string = getCookieDomain(process.env.ACCOUNT_OVERAY_STUDIO);
export function removeToken(key: string) {
  if (TARGET_DOMAIN) {
    Cookies.remove(key, {
      domain: TARGET_DOMAIN,
      path: '/'
    });
  }
}