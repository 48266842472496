import { API_ENDPOINTS } from '@/data/network/rest/client/api-endpoint';
import { AUTH_REFRESH_KEY, AUTH_TOKEN_KEY } from '@/lib/constants';
import { CustomAxiosResponse } from '@/ts/interfaces';
import { TokenResponse } from '@/ts/interfaces/overay_studio/index';
import axios from 'axios';
import Cookies from 'js-cookie';
import process from 'process';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

const AuthAxios = axios.create({
  baseURL: process.env.OVERAY_STUDIO_AUTH_REST_API_ENDPOINT,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  }
});
export const Axios = axios.create({
  baseURL: process.env.OVERAY_STUDIO_REST_API_ENDPOINT,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

Axios.interceptors.request.use(config => {
  if (
    config.headers.Authorization === '' ||
    config.headers.Authorization === undefined
  ) {
    const value = localStorage.getItem(AUTH_TOKEN_KEY);
    if (Boolean(value)) {
      const accessToken: string = JSON.parse(value ?? '');
      if (Boolean(accessToken)) {
        Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
  }
  return config;
});

interface RefreshResponse {
  accessToken?: string;
  error?: any;
}
let isRefreshing: boolean = false;

const refreshSubscribers: Array<(response: RefreshResponse) => void> = [];

const subscribeTokenRefresh = (
  callback: (response: RefreshResponse) => void
) => {
  refreshSubscribers.push(callback);
};

const onRefreshed = (response: RefreshResponse) => {
  while (refreshSubscribers.length) {
    const callback = refreshSubscribers.pop();
    if (callback) {
      callback(response);
    }
  }
};
Axios.interceptors.response.use(
  response => response,
  async error => {
    const {
      config,
      response: { status }
    } = error;

    if (status === 401) {
      if (!isRefreshing) {
        delete Axios.defaults.headers.common.Authorization;
        isRefreshing = true;
        let returnAxios;
        try {
          const accessToken = await getRefreshToken();
          isRefreshing = false;
          if (accessToken != null && accessToken.length > 0) {
            config.headers.Authorization = `Bearer ${accessToken}`;
            Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            returnAxios = await Axios(config);
            onRefreshed({ accessToken });
          } else {
            onRefreshed({ error });
            returnAxios = await Promise.reject(error);
          }
        } catch (refreshError) {
          isRefreshing = false;
          onRefreshed({ error: refreshError });
          returnAxios = await Promise.reject(refreshError);
        }
        return returnAxios ?? (await Promise.reject(error));
      } else {
        return await new Promise(resolve => {
          subscribeTokenRefresh(({ accessToken, error }: RefreshResponse) => {
            if (accessToken) {
              config.headers.Authorization = `Bearer ${accessToken}`;
              resolve(Axios(config));
            } else if (error !== undefined) {
              resolve(Promise.reject(error));
            }
          });
        });
      }
    }

    return await Promise.reject(error);
  }
);

const getRefreshToken = async (): Promise<string> => {
  try {
    const { data } = await AuthAxios.post<CustomAxiosResponse<TokenResponse>>(
      API_ENDPOINTS.REFRESH_TOKEN,
      {
        client_id: process.env.OVERAY_STUDIO_CLIENT_ID,
        client_secret: process.env.OVERAY_STUDIO_CLIENT_SECRET,
        grant_type: AUTH_REFRESH_KEY,
        refresh_token: Cookies.get(AUTH_REFRESH_KEY)
      },
      {
        headers: {
          Authorization: '',
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    );
    const accessToken = data.data?.access_token;
    localStorage.setItem(AUTH_TOKEN_KEY, JSON.stringify(accessToken));
    Axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    return accessToken;
  } catch (e) {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class HttpClient {
  static async get<T>(url: string, params?: unknown) {
    const response = await Axios.get<CustomAxiosResponse<T>>(url, { params });
    return response.data.data;
  }

  static async post<T>(url: string, data: unknown, options?: any) {
    const response = await Axios.post<CustomAxiosResponse<T>>(
      url,
      data,
      options
    );
    return response.data.data;
  }

  static async put<T>(url: string, data: unknown) {
    const response = await Axios.put<CustomAxiosResponse<T>>(url, data);
    return response.data.data;
  }

  static async patch<T>(url: string, data: unknown) {
    const response = await Axios.patch<CustomAxiosResponse<T>>(url, data);
    return response.data.data;
  }

  static async delete<T>(url: string) {
    const response = await Axios.delete<CustomAxiosResponse<T>>(url);
    return response.data.data;
  }
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class HttpAuthClient {
  static async post<T>(url: string, data: unknown) {
    const response = await AuthAxios.post<CustomAxiosResponse<T>>(url, data);
    return response.data.data;
  }
}
