import { AUTH_TOKEN_KEY } from '@/lib/constants';
import { atom, useAtom } from 'jotai';
import { atomWithStorage, createJSONStorage, RESET } from 'jotai/utils';
import Cookies from 'js-cookie';
import { removeToken as removeCookieWithDomain } from '../lib/cookies';

export const accessTokenAtom = atomWithStorage(AUTH_TOKEN_KEY, '', {
  ...createJSONStorage(() => localStorage),
  delayInit: true
});

export const authorizationAtom = atom(get => {
  const accessToken = get(accessTokenAtom);
  const isAuthorized = Boolean(accessToken);
  return isAuthorized;
});

export function useUpdateAccessToken() {
  const [accessTokenFromStorage, setAccessToken] = useAtom(accessTokenAtom);
  if (accessTokenFromStorage === '') {
    const token = Cookies.get(AUTH_TOKEN_KEY);
    if (Boolean(token)) {
      removeCookieWithDomain(AUTH_TOKEN_KEY);
      setAccessToken(token ?? RESET);
    }
  }
}
