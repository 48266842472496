/*
  For setting to use `next-intl` library
  https://next-intl-docs.vercel.app/docs/getting-started/app-router/with-i18n-routing#i18nts
*/

import { createSharedPathnamesNavigation } from 'next-intl/navigation';
import { getRequestConfig } from 'next-intl/server';

export const i18nLocales = ['en', 'ko'] as const;
export const defaultLocale = 'en'; // 기본 로케일 설정
export type Locales = (typeof i18nLocales)[number];

export default getRequestConfig(async ({ requestLocale }) => {
  // 로케일 가져오기
  let locale = await requestLocale;

  // 로케일이 유효하지 않은 경우 기본 로케일로 설정
  if (!locale || !i18nLocales.includes(locale as Locales)) {
    locale = defaultLocale;
  }

  return {
    locale, // 반환값에 로케일 추가
    messages: (await import(`../public/locales/${locale}.json`)).default // 메시지 로드
  };
});

export const { useRouter: useI18nRouter, usePathname: useI18nPathname } =
  createSharedPathnamesNavigation({
    locales: i18nLocales
  });
