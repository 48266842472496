import { IMAGE_ENDPOINTS } from '@/lib/constants/resources';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
interface Props extends React.AnchorHTMLAttributes<HTMLInputElement> {
  themeMode: 'black' | 'white';
}
export const Logo: React.FC<Props> = ({
  themeMode
}) => {
  return <Link href="/" className="-m-1.5 p-1.5" data-sentry-element="Link" data-sentry-component="Logo" data-sentry-source-file="Logo.tsx">
      <span className="sr-only">Overay Studio</span>
      <div className="inline-block h-auto w-72 overflow-hidden pt-[8px] relative" aria-hidden="true">
        <Image width="1172" height="200" src={themeMode === 'white' ? IMAGE_ENDPOINTS.LOGO_BLACK_BETA : IMAGE_ENDPOINTS.LOGO_WHITE_BETA} alt="Overay Studio Logo" loading="eager" priority data-sentry-element="Image" data-sentry-source-file="Logo.tsx" />
      </div>
    </Link>;
};