import { Union } from '@/ts/types';

export const AUTH_TOKEN_KEY = 'access_token';
export const AUTH_REFRESH_KEY = 'refresh_token';

export const ACCOUNT_SETTING_NAV = {
  DASHBOARD: 'dashboard',
  PROFILE_EDIT: 'account',
  SUBSCRIBE: 'subscribe'
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ACCOUNT_SETTING_NAV = Union<typeof ACCOUNT_SETTING_NAV>;
