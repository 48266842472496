import { IMAGE_ENDPOINTS } from '@/lib/constants/resources';
import Image from 'next/image';
interface CompanyLogoProps {
  theme: 'black' | 'white';
}
export default function CompanyLogo({
  theme
}: CompanyLogoProps) {
  return <>
      <span className="sr-only">Overay Inc.</span>
      <div className="inline-block w-[120px] h-auto" aria-hidden>
        <Image src={theme === 'white' ? IMAGE_ENDPOINTS.OVERAY_LOGO_WHITE : IMAGE_ENDPOINTS.OVERAY_LOGO_BLACK} alt="Overay Inc. Logo" width={160} height={50} loading="eager" priority data-sentry-element="Image" data-sentry-source-file="CompanyLogo.tsx" />
      </div>
    </>;
}