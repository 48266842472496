import { useUser } from '@/data/network/rest/user';
import { AUTH_TOKEN_KEY } from '@/lib/constants';
import { useRouter } from 'next/navigation';
import React, { useEffect } from 'react';
interface Props {
  children: React.ReactNode;
}
export function PrivateRoute({
  children
}: Props) {
  const router = useRouter();
  const {
    me,
    isAuthorized
  } = useUser();
  const isUser = !!me;
  useEffect(() => {
    const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
    if (!Boolean(authToken)) {
      router.replace('/');
    }
  }, [me, isAuthorized, router]);
  if (!isUser || !isAuthorized) {
    return null;
  }
  return <>{children}</>;
}