'use client';

import { useI18nPathname, useI18nRouter, type Locales } from 'i18n/request';
import classNames from 'classnames';
import { useLocale, useTranslations } from 'next-intl';
import React, { useState } from 'react';
import CompanyLogo from '../Icons/CompanyLogo';
export interface Props {
  themeMode: 'black' | 'white';
}
interface Menu {
  name: string;
  href: string | undefined;
  newWindow?: boolean;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
}
interface NavSection {
  category: string;
  menus: Menu[];
}
export const Footer: React.FC<Props> = ({
  themeMode
}) => {
  const t = useTranslations('footer');
  const locale = (useLocale() ?? 'en') as Locales;
  const i18nRouter = useI18nRouter();
  const i18nPathname = useI18nPathname();
  const isBlackBackground = themeMode === 'black';
  const isWhiteBackground = themeMode === 'white';
  const [selectedLanguage, setSelectedLanguage] = useState<Locales>(locale);
  const navigation: NavSection[] = [{
    category: t('support'),
    menus: [{
      name: t('faq'),
      href: '/faq'
    }, {
      name: t('guide'),
      href: process.env.GITBOOK_URL,
      newWindow: true
    }, {
      name: t('download_vr'),
      href: '/#VRAppDownloadSection'
    }, {
      name: t('download_pc_host'),
      href: '/#DownloadSection'
    }]
  }, {
    category: t('company'),
    menus: [{
      name: t('about'),
      href: '/about'
    }, {
      name: t('jobs'),
      href: 'https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=UmpiclIzWUxRcUp1MmlQOUJJb3VPdz09',
      newWindow: true
    }]
  }, {
    category: t('legal'),
    menus: [{
      name: t('privacy'),
      href: '/policy/privacy'
    }, {
      name: t('terms_of_service'),
      href: '/policy/service'
    }, {
      name: t('open_source_sw_notice'),
      href: '/policy/opensource'
    }]
  }, {
    category: t('join_us'),
    menus: [{
      name: t('discord'),
      href: process.env.DISCORD_INVITE_URL ?? 'https://discord.gg/hgKnEHfwkr',
      icon: (props: React.SVGProps<SVGSVGElement>) => <svg fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
              <path d="M20.317 4.3698a19.7913 19.7913 0 00-4.8851-1.5152.0741.0741 0 00-.0785.0371c-.211.3753-.4447.8648-.6083 1.2495-1.8447-.2762-3.68-.2762-5.4868 0-.1636-.3933-.4058-.8742-.6177-1.2495a.077.077 0 00-.0785-.037 19.7363 19.7363 0 00-4.8852 1.515.0699.0699 0 00-.0321.0277C.5334 9.0458-.319 13.5799.0993 18.0578a.0824.0824 0 00.0312.0561c2.0528 1.5076 4.0413 2.4228 5.9929 3.0294a.0777.0777 0 00.0842-.0276c.4616-.6304.8731-1.2952 1.226-1.9942a.076.076 0 00-.0416-.1057c-.6528-.2476-1.2743-.5495-1.8722-.8923a.077.0770 0 01-.0076-.1277c.1258-.0943.2517-.1901.3718-.2874a.0743.0743 0 01.0776-.0105c3.9278 1.7933 8.18 1.7933 12.0614 0a.0739.0739 0 01.0785.0095c.1202.0984.246.1942.3728.2885a.077.0770 0 01-.0065.1276 12.2986 12.2986 0 01-1.873.8914.0766.0766 0 00-.0407.1067c.3604.698.7719 1.3628 1.225 1.9932a.076.0760 0 00.0842.0286c1.961-.6067 3.9495-1.5219 6.0023-3.0294a.077.0770 0 00.0313-.0552c.5004-5.177-.8382-9.6739-3.5485-13.6604a.061.0610 0 00-.0312-.0286zM8.02 15.3312c-1.1825 0-2.1487-1.0857-2.1487-2.419 0-1.3332.9555-2.4189 2.1487-2.4189 1.2107 0 2.1759 1.0952 2.1487 2.419 0 1.3332-.9555 2.4189-2.1487 2.4189zm7.9748 0c-1.1825 0-2.1487-1.0857-2.1487-2.419 0-1.3332.9554-2.4189 2.1487-2.4189 1.2107 0 2.1759 1.0952 2.1487 2.419 0 1.3332-.938 2.4189-2.1487 2.4189z" />
            </svg>
    }, {
      name: t('linkedin'),
      href: process.env.LINKED_IN_URL,
      icon: (props: React.SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
              <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
            </svg>
    }, {
      name: t('x'),
      href: process.env.X_URL,
      icon: (props: React.SVGProps<SVGSVGElement>) => <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
            </svg>
    }, {
      name: t('youtube'),
      href: process.env.YOUTUBE_URL,
      icon: (props: React.SVGProps<SVGSVGElement>) => <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path fillRule="evenodd" d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z" clipRule="evenodd" />
            </svg>
    }]
  }];
  const handleInputChange = async (newLocale: Locales) => {
    try {
      i18nRouter.push(i18nPathname, {
        locale: newLocale
      });
      i18nRouter.refresh();
      setSelectedLanguage(newLocale);
    } catch (error) {
      // Handle error if necessary
    }
  };
  return <footer className={classNames({
    'bg-black-background': isBlackBackground,
    'bg-white': isWhiteBackground
  })} aria-labelledby="footer-heading" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="pb-8 xl:grid xl:grid-cols-5 xl:gap-8">
          <div className="grid grid-cols-2 gap-8 xl:col-span-4 md:grid md:grid-cols-4 md:gap-8">
            {navigation.filter(subNav => subNav.category !== t('join_us')).map(subNav => <div key={subNav.category}>
                  <h3 className={classNames('text-base font-medium', {
              'text-white': isBlackBackground,
              'text-gray-900': isWhiteBackground
            })}>
                    {subNav.category}
                  </h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {subNav.menus.map(menu => <li key={menu.name}>
                        <a href={menu.href} target={menu.newWindow === true ? '_blank' : undefined} rel={menu.newWindow === true ? 'noopener noreferrer' : undefined} className={classNames('flex items-center space-x-2 text-base', {
                  'text-gray-300 hover:text-overay-500': isBlackBackground,
                  'text-gray-500 hover:text-gray-900': isWhiteBackground
                })}>
                          {menu.icon && <menu.icon aria-hidden="true" className="w-5 h-5" />}
                          <span>{menu.name}</span>
                        </a>
                      </li>)}
                  </ul>
                </div>)}

            {/* Join Us Category Rendering */}
            {navigation.filter(subNav => subNav.category === t('join_us')).map(subNav => <div key={subNav.category}>
                  <h3 className={classNames('text-base font-medium pl-1.5', {
              'text-white': isBlackBackground,
              'text-gray-900': isWhiteBackground
            })}>
                    {subNav.category}
                  </h3>
                  <ul role="list" className="mt-5 space-y-4">
                    {/* Discord Icon with Border and "Join our Discord" Text */}
                    {subNav.menus.filter(menu => menu.name.toLowerCase() === t('discord').toLowerCase()).map(menu => <li key={menu.name} className="flex">
                          <a href={menu.href} target={menu.newWindow === true ? '_blank' : undefined} rel={menu.newWindow === true ? 'noopener noreferrer' : undefined} className={classNames('flex flex-col items-center space-y-2 text-lg font-bold', {
                  'text-gray-300 hover:text-overay-500': isBlackBackground,
                  'text-gray-500 hover:text-gray-900': isWhiteBackground
                })}>
                            {menu.icon && <div className={classNames('flex items-center justify-center p-2.5 rounded-full border', {
                    'border-white': isBlackBackground,
                    'border-black': isWhiteBackground
                  })}>
                                <menu.icon aria-hidden="true" className="w-5 h-5" />
                                <span className="ml-2 text-sm font-semibold">
                                  {t('join_our_discord')}
                                </span>
                              </div>}
                          </a>
                        </li>)}

                    <li className="flex pl-2 mt-4 space-x-4">
                      {subNav.menus.filter(menu => menu.name.toLowerCase() !== t('discord').toLowerCase()).map(menu => <a key={menu.name} href={menu.href} target={menu.newWindow === true ? '_blank' : undefined} rel={menu.newWindow === true ? 'noopener noreferrer' : undefined} className={classNames('flex items-center', {
                  'text-gray-300 hover:text-overay-500': isBlackBackground,
                  'text-gray-500 hover:text-gray-900': isWhiteBackground
                })}>
                            {menu.icon && <menu.icon aria-hidden="true" className="w-5 h-5" />}
                          </a>)}
                    </li>
                  </ul>
                </div>)}
          </div>

          <div className="mt-12 xl:mt-0">
            <h3 className={classNames('text-base font-medium', {
            'text-white': isBlackBackground,
            'text-gray-900': isWhiteBackground
          })}>
              {t('language')}
            </h3>
            <form className="mt-4 sm:max-w-xs">
              <fieldset className="w-full">
                <label className="sr-only">Language</label>
                <div className="relative">
                  <select id="language" name="language" value={selectedLanguage} onChange={e => {
                  handleInputChange(e.target.value as Locales).catch(() => {
                    // Handle error if necessary
                  });
                }} className="block w-full py-2 pl-3 pr-10 text-base text-gray-900 bg-white border border-gray-300 rounded-md appearance-none bg-none focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                    <option value="en">English</option>
                    <option value="ko">한국어</option>
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                    <svg className="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-sentry-element="svg" data-sentry-source-file="Footer.tsx">
                      <path fillRule="evenodd" d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z" clipRule="evenodd" data-sentry-element="path" data-sentry-source-file="Footer.tsx" />
                    </svg>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        <div className="pt-8 mt-8 border-t border-gray-200 md:flex md:items-center md:justify-between">
          <div className="flex gap-x-4 flex-wrap">
            <div className="flex gap-x-2 items-center">
              <CompanyLogo theme={i18nPathname === '/accounts' ? 'black' : 'white'} data-sentry-element="CompanyLogo" data-sentry-source-file="Footer.tsx" />
              <p className="-mt-[1px] text-sm">{t('company_name')}</p>
            </div>
            <address className={classNames(locale === 'ko' ? 'flex space-x-4 divide-x-[1px] divide-gray-400' : 'flex flex-col justify-center', 'text-gray-400 text-xs font-normal not-italic')}>
              <div>
                <p>{t('company_ceo')}</p>
                {locale === 'ko' && <p>사업자번호 : 871-81-02641</p>}
              </div>
              <div className={locale === 'ko' ? 'pl-4' : ''}>
                <p>{t('company_address')}</p>
                {locale === 'ko' && <p>전화번호 : 042-252-0708</p>}
              </div>
            </address>
          </div>
          <p className="mt-8 text-base text-gray-400 md:order-1 md:mt-0">
            &copy; 2023 Overay Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>;
};