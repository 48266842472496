export const API_ENDPOINTS = {
  REFRESH_TOKEN: '/oauth2/token',
  REVOKE_TOKEN: '/oauth2/revoke',
  USERS_ME: '/users/me',
  USERS_PROFILE: '/users/profile',
  USERS_DELETE: '/accounts/delete',
  USERS_CHANGE_PASSWORD: '/accounts/password',
  USERS_CREATE_PASSWORD: '/accounts/create-password',
  USERS_PAYMENTS_COMPLETE: 'users/payments/complete',
  USERS_CARD_INFO: '/users/card-info',
  USERS_CHANGE_CARD: '/subscriptions/setup-billing',
  ADVANCED_FEATURE_PRODUCTS: 'products/advanced-feature-products',
  PRESIGNED_POST: '/upload/direct/start',
  FILE_DELETE: '/delete/standard',
  ACCOUNT: '/accounts',
  SOCIAL_ACCOUNT: '/social-accounts',
  LATEST_OVERAY_PLATFORMS:
    'managements/applications/overaystudio/downloads/latest',
  PLANS: '/plans',
  SUBSCRIPTIONS_PRE_ORDERS: '/subscriptions/pre-orders',
  SUBSCRIPTIONS_PAYMENTS: '/subscriptions/payments',
  SUBSCRIPTIONS_TRANSACTIONS: '/subscriptions/transactions',
  SUBSCRIPTIONS_CANCEL: '/subscriptions/cancel'
};
